import Axios from 'axios';
import { groupBy, map } from 'lodash';
import * as React from 'react';
import { Highlighter, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { AffinityClient } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: AffinityClient[];
  onChange: (accounts: AffinityClient[]) => void;
  multiple: boolean;
  licensor?: number;
  filter?: (client: AffinityClient) => boolean;
  preloadedLicensors?: AffinityClient[];
}

type Status = LoadableStatuses<AffinityClient[], string>;

export const LicensorTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if (props.preloadedLicensors && props.preloadedLicensors.length) {
      setStatus({ content: props.preloadedLicensors, state: 'success' });
    } else {
      getAccounts('');
    }
  },              []);

  async function getAccounts(query: string) {
    setStatus({ state: 'loading' });
    try {
      const l = await Axios.get('/api/licensors?includes=profile');
      const licensors = l.data.data.map((u: any) => new AffinityClient(u));
      const selected = props.selected.slice();
      let shouldUpdate = false;
      for (let i = 0; i < selected.length; i++) {
        const c = selected[i];
        if (c.shortName === 'Loading') {
          shouldUpdate = true;
          const foundLic = licensors.find((lic: AffinityClient) => Number(lic.id) === Number(c.id));
          if (foundLic) {
            selected[i].shortName = foundLic.shortName;
          }
        }
      }
      if (shouldUpdate) {
        props.onChange(selected);
      }
      setStatus({ content: licensors, state: 'success' });
    } catch (e) {
      setStatus({ state: 'success', content: [] });
    }
  }

  function renderMenu(licensors: AffinityClient[], menuProps: any) {
    let index = 0;
    const filteredLicensors = [];
    for (let i = 0; i < licensors.length; i++) {
      if (licensors[i].market) {
        filteredLicensors.push(licensors[i]);
      }
    }
    const grouped = groupBy(filteredLicensors, l => l.market.name);
    const items: JSX.Element[] = [];
    const sortedMarkets = Object.keys(grouped).sort();
    for (let i = 0; i < sortedMarkets.length; i++) {
      const market = sortedMarkets[i];
      if (items.length) {
        items.push(<Menu.Divider key={`${market}-divider`} />);
      }
      items.push(<Menu.Header key={`${market}-header`}>{market}</Menu.Header>);
      const groupItems = map(grouped[market], (licensor) => {
        const item = (
          <MenuItem key={index} option={licensor} position={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 25, height: 25, marginRight: 10 }}>
                {licensor.affinityImage && !licensor.affinityImage.isUsingDefault ? (
                  <img className="img-responsive" alt="" src={licensor.affinityImage.getSize('th')} />
                ) : null}
              </div>
              <span><Highlighter search={menuProps.text}>{licensor.name}</Highlighter></span>
            </div>
          </MenuItem>
        );
        index++;
        return item;
      });
      items.push(...groupItems);
    }
    return <Menu {...menuProps}>{items}</Menu>;
  }

  let options: AffinityClient[] = [];
  if (status.state === 'success' && status.content) {
    options = props.filter ? status.content.filter(props.filter) : status.content;
  }
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      ref={ref}
      id={'licensor-typeahead'}
      placeholder={props.multiple ? 'Select Licensors' : 'Select Licensor'}
      options={options}
      selected={props.selected}
      onChange={(v: AffinityClient[]) => {
        props.onChange(v);
        if (!v.length && ref.current) {
          ref.current.focus();
          if (ref.current._showMenu) {
            ref.current._showMenu();
          }
        }
      }}
      multiple={props.multiple}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      labelKey={(item: AffinityClient) => item.shortName}
      renderMenu={renderMenu}
      clearButton
    />
  );
};
