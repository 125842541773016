import * as moment from 'moment';
import { AffinityImage } from '.';

export class AffinityClient {
  id: number;
  name: string;
  image: { th: string, sm: string, md: string, or: string };
  affinityImage: AffinityImage;
  shortName: string;
  market: { id: number, name: string };
  profile: AffinityClientProfile;
  confirmedAuthorizedAt?: moment.Moment | null;
  dateToReverify?: moment.Moment | null;
  totalProducts?: number | null;
  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.short_name ? data.short_name : data.name;
      this.shortName = data.short_name;
      this.confirmedAuthorizedAt = data.pivot && data.pivot.confirmed_authorized_at ? moment(data.pivot.confirmed_authorized_at) : null;
      this.totalProducts = data.total_products ? data.total_products : 0;
      if (data.image) {
        this.image = data.image;
        this.affinityImage = AffinityImage.fromApi(data.image);
      } else {
        this.affinityImage = new AffinityImage();
      }
      if (data.market) {
        this.market = data.market;
      } else {
        this.market = { id: 0, name: 'Other' };

      }

      if (data.profile) {
        this.profile = {
          exclusivelyReviewsDesigns: data.profile.exclusively_reviews_designs,
          designRoyaltyIdentification: data.profile.design_royalty_identification,
          acceptingDesigns: data.profile.accepting_designs,
          marketplaceProducts: data.profile.marketplace_products,
        };
      }
    }
  }

}

interface AffinityClientProfile {
  exclusivelyReviewsDesigns: boolean;
  designRoyaltyIdentification: boolean;
  acceptingDesigns: boolean;
  marketplaceProducts: boolean;
}

export class AffinityMarket {
  id: number;
  name: string;
  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
    }
  }

  static marketFromId(id: string | number) {
    const mid = Number(id);
    const market =  new AffinityMarket();
    market.id = mid;
    switch (mid) {
      case 1:

        market.name = 'Greek';
        break;
      case 2:
        market.name = 'Collegiate';
        break;
      case 3:
        market.name = 'Associations';
        break;
      case 5:
        market.name = 'Charitable';
        break;
      default:
        // Force market to be greek if unknown
        market.name = 'Greek';
        market.id = 1;
        break;
    }
    return market;
  }
}
