import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { WebBotScanRequest, WebBotScanRequestApiResponse } from '../../../shared/WebBotScanRequest';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner, PaginationFooter, UrlTypeTooltip } from '../../shared';

interface Paginator {
  total: number;
  page: number;
  lastPage: boolean;
  totalPages: number;
}

export const ScanHistoryPage = () => {
  const location = useLocation();
  const [scanHistory, setScanHistory] = React.useState<WebBotScanRequest[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<Paginator>({
    total: 0,
    page: 0,
    lastPage: false,
    totalPages: 0,
  });
  const routeMatch = useRouteMatch();
  const routeParams = useParams();
  const history = useHistory();

  React.useEffect(() => {
    getScanHistory();
  },              [location.search]);

  const setPage = (newPage: number) => {
    const temp = pagination;
    temp.page = newPage;
    setPagination(temp);
    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, page: newPage };
    const cleaned = omitBy(currentParams, isNil);

    const baseUrl = location.pathname;
    history.push(`${baseUrl}?${queryString.stringify(cleaned)}`);
  };

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  function getScanSellerDetailPageLink(id:number) {
    if (routeParams['vendorId']) {
      return `/vendors/${getAccountID()}/scans/sellers/${id}`;
    }
    if (routeParams['clientId']) {
      return `/clients/${getAccountID()}/scans/sellers/${id}`;
    }
    return `/scans/sellers/${id}`;
  }

  const getScanHistory = async () => {
    setLoading(true);
    let clientId = null;
    if (routeMatch !== null && routeMatch.params['clientId']) {
      clientId = routeMatch.params['clientId'];
    }
    const query = queryString.parse(location.search);
    const params = {
      clientId,
      page: query.page,
    };
    const cleaned = omitBy(params, isNil);
    const s = await Axios.get('/api/enforcement-bots/requests', { params: cleaned });
    const paginationResult = s.data.meta.pagination;
    setPagination({
      total: paginationResult.total,
      page: paginationResult.current_page,
      lastPage: paginationResult.total_pages === paginationResult.current_page,
      totalPages: paginationResult.total_pages,
    });
    const data = s.data.data.map((r:WebBotScanRequestApiResponse) => WebBotScanRequest.fromApi(r));
    setScanHistory(data);
    setLoading(false);
  };

  const html =
      <FullContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h3><strong>Scan History</strong></h3>
    </div>

    {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
      <div className="table-responsive">
        <table className="table table-portal table-hover">
          <thead>
            <tr>
              <th>Type</th>
              <th>Details</th>
              <th>Platform</th>
              <th># of Sellers</th>
              <th>Requested</th>
              <th>Started</th>
              <th>Completed</th>
              <th>Progress</th>
              <th>Results</th>
            </tr>
          </thead>
          <tbody>
          {scanHistory.map(s => (
            <tr key={s.id}>
              <td>
                <div className="label label-default">{s.scanType.toUpperCase()}</div>
              </td>
              <td>
                {
                  s.scanType === 'keyword' ? `"${s.search}"` : null
                }
                {
                  s.scanType === 'licensor' && s.licensor ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ width: 75, height: 75 }} src={s.licensor.image.sm} />
                      <span style={{ marginLeft: 10 }}>
                        {s.licensor.shortName}
                      </span>
                    </div>  : null
                }
                {
                  s.scanType === 'seller' && s.seller ? (s.seller.linkedAccount  ?
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => { history.push(getScanSellerDetailPageLink(s.seller.id)); }}>
                      <img style={{ width: 75, height: 75 }} src={s.seller.linkedAccount.logo.getSize('sm')} />
                      <span style={{ marginLeft: 10 }}>
                        {s.seller.linkedAccount.accountName}
                      </span>
                    </div>
                    :  <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => { history.push(getScanSellerDetailPageLink(s.seller.id)); }}>
                      <img style={{ width: 75, height: 75 }} src={s.seller.image.getSize('sm')} />
                      <span style={{ marginLeft: 10 }}>
                        {s.seller.platformUsername}
                      </span>
                    </div>
                    ) : null
                }
              </td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10  }}>
                  <UrlTypeTooltip key={s.platform.id} type={s.platform} />
                </div>
              </td>
              <td>
                {s.endTime ? s.sellerCount : '-'}
              </td>
              <td>
                {s.createdAt ? s.createdAt.format('MMM DD, YYYY') : '-'}
              </td>
              <td>
                { s.startTime ? s.startTime.format('MMM DD, YYYY') : '-'}
              </td>
              <td>
                {s.endTime
                  ? <>
                    {s.endTime.format('MMM DD, YYYY')}
                    {
                      s.hasError
                        ? <i className="fa fa-exclamation-triangle text-danger" style={{ marginLeft: 5 }}/>
                        : null
                    }
                  </>
                  : ''
                }
              </td>

              <td>
                <div style={{ display: 'flex' }}>
                  <div className="progress" style={{ height: 10, width: '70%' }}>
                    <div className={s.progress >= 100 ? 'progress-bar progress-bar-success' : 'progress-bar'} role="progressbar" aria-valuenow={s.progress} aria-valuemin={0}
                         aria-valuemax={100} style={{ width: `${s.progress}%` }}>
                    </div>
                  </div>
                  <span className={ s.progress >= 100 ? 'text-success text-right' : 'text-primary text-right'} style={{ marginLeft:10, lineHeight:'10px' }}>{s.progress}%</span>
                </div>
                </td>
              <td>
                {s.endTime ? s.totalResults : '-'}
              </td>
            </tr>
          ))}

          </tbody>
        </table>
      </div>

    </div>}

        {loading && pagination ? null : <PaginationFooter
          totalResults={pagination.total}
          hasNext={!pagination.lastPage}
          currentPage={pagination.page}
          setPage={setPage}
          totalPages={pagination.totalPages}
          showFirstLast={true}
        />}
  </FullContent>;

  return html;
};
