
export class AccountWebUrl {
  id: number;
  type: AccountWebUrlType;
  url: string;

  static fromApi(data: any) {
    const a = new AccountWebUrl();
    a.id = data.id;
    a.type = AccountWebUrlType.fromApi(data.type);
    a.url = data.url;
    return a;
  }

}

export class AccountWebUrlType {
  id: number;
  name: string;
  icon: string;
  color: string;
  dmca: {
    email: string | null,
    formUrl: string | null;
  } | null;
  hasScanRequests: boolean;
  hasSellerScans: boolean;

  static fromApi(data: any) {
    const t = new AccountWebUrlType();
    t.id = data.id;
    t.name = data.name;
    t.icon = data.icon;
    t.color = data.color;

    if (data.dmca) {
      t.dmca = {
        email: data.dmca.email,
        formUrl: data.dmca.form_url,
      };
    } else {
      t.dmca = null;
    }

    t.hasScanRequests = data.has_scan_requests === true;
    t.hasSellerScans = data.has_seller_scans === true;

    return t;
  }
}
