import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import { Filters } from '../../shared';

export class WebBotUrlFilters extends Filters {
  defaultFilters = {
    search: '',
    vendorId: null,
    userId: null,
    phaseId: null,
    page: 1,
    perPage: 24,
    quarter: '',
    sortBy: '',
    sortDir: 'desc',
    status: 0,
    platform: 0,
  };
  status: number = 0;
  platform: number = 0;

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.platform = queryValues.platform ? queryValues.platform : this.platform;
    this.sortBy = queryValues.sort ? queryValues.sort : this.sortBy;
  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      start_date: this.startDate.length ? this.startDate : null,
      end_date: this.endDate.length ? this.endDate : null,
      page: this.page !== 1 ? this.page : null,
      market: this.market.length ? this.market : null,
      user: this.userId ? this.userId : null,
      sort: this.sortBy.length ? this.sortBy : null,
      platform: Number(this.platform) ? this.platform : null,
      status: Number(this.status) ? this.status : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  generateApiParamString(licensor?: string) {
    const queryParams = {
      keyword: this.search.length ? this.search : null,
      page: this.page !== 1 ? this.page : null,
      sort: this.sortBy.length ? this.sortBy : null,
      platform: Number(this.platform) ? this.platform : null,
      status: Number(this.status) ? this.status : null,
      licensor: licensor ? licensor : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;

  }

  setFilters(filters: any) {
    const newFilters = {
      search : this.search,
      perPage: this.perPage,
      page: this.page,
      sortBy: this.sortBy,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
      platform: this.platform,
      ...filters,
    };
    this.search = newFilters.search;
    this.perPage = newFilters.perPage;
    this.page = newFilters.page;
    this.status = newFilters.status;
    this.startDate = newFilters.startDate;
    this.endDate = newFilters.endDate;
    this.market = newFilters.market;
    this.userId = newFilters.userId;
    this.sortBy = newFilters.sortBy;
    this.platform = newFilters.platform;
  }

  getQueryParams() {
    const queryProps = {
      search: this.search !== this.defaultFilters.search ? this.search : null,
      limit: this.perPage !== this.defaultFilters.perPage ? this.perPage : null,
      page: this.page !== this.defaultFilters.page ? this.page : null,
      status: this.status !== this.defaultFilters.status ? this.status : null,
      platform: this.platform !== this.defaultFilters.platform ? this.platform : null,
      sort: this.sortBy !== this.defaultFilters.sortBy ? this.sortBy : null,
    };
    Object.keys(queryProps).forEach(key => (queryProps[key] === null) && delete queryProps[key]);
    return queryProps;
  }

  get allFilters() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
      status: this.status,
      platform: this.platform,
    };
  }

  static getInitialFilters(search: string) {
    const filters = new WebBotUrlFilters();
    filters.setQueryParamsFromLocation(search);
    return filters;
  }

}
