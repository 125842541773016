import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle, faChevronLeft, faChevronRight, faCircle, faCogs, faPlus, faTh, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal, SplitButton } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Vendor } from '../../../shared';
import User from '../../../shared/User';
import { WebBotSeller } from '../../../shared/WebBotSeller';
import { WebBotUrl, WebBotUrlStatuses } from '../../../shared/WebBotUrl';
import { ContentWithSidebar, FullContent } from '../../ContentFrame';
import { AffinityLeafletImage, CurrencyLabel, ExternalPlatformLogo, GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType, TrademarkTypeahead, VendorTypeahead } from '../../shared';
import { WebBotUrlFilters } from '../WebBotUrlFilters';

export const WebBotUrlPage = () => {
  const match = useRouteMatch();
  const params = useParams();
  const history = useHistory();
  const [url, setUrl] = React.useState<WebBotUrl>(new WebBotUrl());
  const [loading, setLoading] = React.useState(true);
  const [filters, setFilters] = React.useState(WebBotUrlFilters.getInitialFilters(location.search));
  const [navigation, setNavigation] = React.useState<{next: number | null, previous: number | null}>({ next: null, previous: null });
  const [applyToSeller, setApplyToSeller] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [accountLinkModal, setAccountLinkModal] = React.useState(false);
  const [accountCreateModal, setAccountCreateModal] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = React.useState<Vendor[]>([]);
  const [accountCreateStatus, setAccountCreateStatus] = React.useState(0);
  const [adminSettingsModal, setAdminSettingsModal] = React.useState(false);
  const [adminUsers, setAdminUsers] = React.useState<User[]>([]);
  const [addLicensorModal, setAddLicensorModal] = React.useState(false);
  const [selectedTrademark, setSelectedTrademark] = React.useState<number | null>(null);
  const adminSettingsForm = useForm();
  const addLicensorForm = useForm({ defaultValues: { licensor: [] } });
  const updateTrademarkForm = useForm({ defaultValues: { trademark: [] } });

  React.useEffect(() => {
    getUrl();
    getNavigation();

  },              [params['id']]);

  React.useEffect(() => {
    getAdminUsers();
  },              []);

  React.useEffect(() => {
    if (url.seller) updateAdminFormValues(url.seller);
  },              [adminSettingsModal]);

  if (!match) {
    return <Redirect to="/" />;
  }

  const getUrl = async () => {
    setLoading(true);
    const response = await fetch(`/api/enforcement/urls/${match.params['id']}`);
    const data = await response.json();
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    if (u.seller) {
      updateAdminFormValues(u.seller);
    }
    setLoading(false);

  };

  const getNavigation = async () => {
    const p = filters.generateApiParamString(match.params['licensorId']);
    const response = await fetch(`/api/enforcement/urls/${match.params['id']}/navigation?${p}`);
    const data = await response.json();
    setNavigation(data);
  };
  const getAdminUsers = async () => {
    const response = await fetch('/api/users?account_id=1');
    const data = await response.json();
    setAdminUsers(data.data.map((u: any) => User.fromApi(u)));
  };

  async function saveAdminSettings(values: any) {
    setSubmitting(true);

    const params = {
      adminUser: values.adminUser,
      closedDate: values.closedDate,
      ignore: values.ignoreSeller,
      sellerStatus: values.status,
    };

    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setSubmitting(false);
    const s = WebBotSeller.fromApi(data.data);
    updateAdminFormValues(s);
    const u = WebBotUrl.fromExisting(url);
    u.seller = s;
    setUrl(u);
    setAdminSettingsModal(false);
  }

  async function addLicensor(values: any) {
    setSubmitting(true);

    const params = {
      licensor: values.licensor[0].id,
    };

    const response = await fetch(`/api/enforcement/urls/${url.id}/add-licensor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setSubmitting(false);
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    setAddLicensorModal(false);
    addLicensorForm.reset();
  }

  async function updateTrademark(values: any) {
    setSubmitting(true);

    const params = {
      licensor: selectedTrademark,
      trademark: values.trademark[0].id,
    };

    const response = await fetch(`/api/enforcement/urls/${url.id}/update-trademark`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setSubmitting(false);
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    setSelectedTrademark(null);
    updateTrademarkForm.reset();
  }

  const updateAdminFormValues = (seller: WebBotSeller) => {
    adminSettingsForm.setValue('adminUser', seller.owner && seller.owner.id);
    adminSettingsForm.setValue('status', seller.closedAt && seller.closedAt.format('YYYY-MM-DD') ? 'closed' : 'open');
    adminSettingsForm.setValue('closedDate', seller.closedAt ? seller.closedAt.format('YYYY-MM-DD') : null);
    adminSettingsForm.setValue('ignoreSeller', seller.ignoreSeller);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const getBackRoute = () => {
    if (params['licensorId']) {
      return `/clients/${params['licensorId']}/enforce/urls?${filters.generateQueryParamString()}`;
    }
    return '/enforce/urls';
  };

  const reviewUrl = async (status: WebBotUrlStatuses) => {
    const response = await fetch(`/api/enforcement/urls/${match.params['id']}/update-status?status=${status}`, {
      method: 'POST' });
    await response.json();
    if (navigation.next) {
      history.push(`${navigation.next}?${filters.generateQueryParamString()}`);
    }
  };

  const linkAccount = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const params = {
      vendor_account_id: selectedVendor[0].id,
    };
    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/assign-vendor?vendor_account_id=${params.vendor_account_id}`, {
      method: 'POST',
    });
    const data = await response.json();
    const s = WebBotSeller.fromApi(data.data);
    updateAdminFormValues(s);
    const u = WebBotUrl.fromExisting(url);
    u.seller = s;
    setUrl(u);
    setAccountLinkModal(false);
    setSubmitting(false);
    setSelectedVendor([]);
  };
  const createAccount = async () => {
    if (submitting) return;

    setSubmitting(true);
    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/create-vendor?status=${accountCreateStatus}`, {
      method: 'POST',
    });
    const data = await response.json();
    const s = WebBotSeller.fromApi(data.data);
    const u = WebBotUrl.fromExisting(url);
    u.seller = s;
    updateAdminFormValues(s);
    setUrl(u);
    setAccountCreateModal(false);
    setSubmitting(false);
  };

  const toggleSellerAuthorization = async (licensor: number, unverify: boolean) => {
    setSubmitting(true);
    const data = {
      unverify,
      licensor_id: licensor,
    };
    const u = url;
    url.seller.toggleAuthorization(licensor);
    setUrl(u);
    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/update-client`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const d = await response.json();
    setSubmitting(false);
  };

  const rescanSeller = async () => {
    await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/rescan`, { method: 'POST' });
    window['growl']('Scan Submitted'); // not sure if I like this yet
  };

  const removeLicensor = async (licensor: number) => {
    setSubmitting(true);
    url.removeLicensor(licensor);
    setUrl(WebBotUrl.fromExisting(url));
    setSubmitting(false);
    await fetch(`/api/enforcement-bots/urls?licensorId=${licensor}&webBotUrlId=${url.id}&sellerId=${url.seller.id}`, { method: 'DELETE' });
  };

  const sidebar = (<div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <div className="enforce-side-nav-actions">
          <Link to={`${navigation.previous || params['id']}?${filters.generateQueryParamString()}`} className="btn btn-default">
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
          <Link to={getBackRoute()} className="btn center-button btn-default">
            <FontAwesomeIcon icon={faTh} />
          </Link>
          <Link to={`${navigation.next || params['id']}?${filters.generateQueryParamString()}`} className="btn btn-default">
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>

        </div>
        <hr />
        <div className="enforce-side-platform-seller">
          <div className="platform">
            <ExternalPlatformLogo type={url.type} />
            <h5><strong>{url.type.name}</strong></h5>

          </div>
          <div className="seller">
            <img src={url.seller.image ? url.seller.image.getSize('th') : ''} />
            <h5><strong><a target="_blank" href={url.seller.sellerUrl}>{url.seller.platformUsername}</a></strong></h5>
          </div>
        </div>
        <hr />
        <div className="text-center enforce-sidebar-status">
          <FontAwesomeIcon className={url.statusColor} icon={faCircle} /> <strong>{url.status.name}</strong>
        </div>

        <a target="_blank" href={url.fullUrl} className="btn btn-block btn-default">
          View Product
        </a>
        <div className="enforce-alerts-block">
          <div style={{ visibility: url.possibleLicensed ? 'visible' : 'hidden' }} className="alert alert-warning">Possibly licensed</div>
          <div className={`alert alert-${url.seller.linkedAccount ? 'success' : 'danger'}`}>{url.seller.linkedAccount ? 'Linked account' : 'No linked account'}</div>
          <div className={`alert alert-${url.seller.hasAnyAuthorization ? 'success' : 'danger'}`}>{url.seller.hasAnyAuthorization ? 'Previous Authorization' : 'No previous authorization'}</div>
        </div>

        <div className="enforce-primary-review-actions">
          <button onClick={() => reviewUrl(WebBotUrlStatuses.Authorized)} style={{ marginTop: 20 }} className="btn  btn-primary btn-block">
            Authorize
          </button>
          <button onClick={() => reviewUrl(WebBotUrlStatuses.NeedsTakedown)} style={{ marginTop: 20 }} className="btn btn-danger bt-block">
            Takedown
          </button>
        </div>
        <DropdownButton componentClass="div" className="btn-block btn-default"  id="status-buttton" title="Additional Options">
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.NeedsReview)} key={WebBotUrlStatuses.NeedsReview} >
            Needs Review
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.Ignored)} key={WebBotUrlStatuses.Ignored} >
            Ignore
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.Removed)} key={WebBotUrlStatuses.Removed} >
            Removed
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.NonInfringing)} key={WebBotUrlStatuses.NonInfringing} >
            Non-Infringing
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.PendingRemoval)} key={WebBotUrlStatuses.PendingRemoval} >
            Pending Removal
          </MenuItem>

        </DropdownButton>

      </div>

    </div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <h5><strong>Linked Account</strong></h5>
        {url.seller.linkedAccount ? <div className="enforce-linked-account">
          <img src={url.seller.linkedAccount.logo.getSize('th')} />
          <div className="enf-account-status">
            <span><strong>{url.seller.linkedAccount.accountName}</strong></span>
            <div>{url.seller.linkedAccount.status.display}</div>
          </div>
          <DropdownButton componentClass="div" pullRight={true} noCaret={true} bsStyle="link" id="case-action" title={(<span>
            <FontAwesomeIcon className="text-muted" icon={faCogs} />
          </span>)}>
            <MenuItem onClick={rescanSeller}>Rescan Seller</MenuItem>
            <MenuItem onClick={console.log}>Ignore and Reset Seller</MenuItem>
            <MenuItem onClick={() => setAdminSettingsModal(true)}>Admin Settings</MenuItem>
          </DropdownButton>
        </div> : <div>
            <button onClick={() => setAccountLinkModal(true)} className="btn btn-block btn-primary">Link Account</button>
            <button onClick={() => setAccountCreateModal(true)} className="btn btn-block btn-primary">Create Account</button>

          </div>}
      </div>
    </div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <h5><strong>Details</strong></h5>
        <div className="enforce-details-grid">
          <div className="enforce-url-details half">
            <span className="text-muted">Last Checked</span>
            <span>{url.createdAt.format('M/DD/YY')}</span>
          </div>
          <div className="enforce-url-details half">
            <span className="text-muted">Last Updated</span>
            <span>{url.updatedAt.format('M/DD/YY')}</span>
          </div>
          <div className="enforce-url-details">
            <span className="text-muted">External Id</span>
            <span>{url.platformIdentifier}</span>
          </div>
          <div className="enforce-url-details">
            <span className="text-muted">External Url</span>
            <span><a target="_blank" href={url.fullUrl} >{url.fullUrl}</a></span>
          </div>

        </div>

      </div>
    </div>

  </div>);
  const body = (<div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <AffinityLeafletImage image={url.imageUrl} />

        <h4><strong>{url.name}</strong></h4>
        <p className="small text-muted">{url.description}</p>

        {url.tags.map(t => (
          <span style={{ marginTop: 5, marginBottom: 5, margin: 5 }} className="label label-primary">{t}</span>
        ))}

        <p><strong>Price: </strong><CurrencyLabel value={url.price} /></p>

      </div>
    </div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <div className="enforce-url-licensors">
          <div className="enforce-url-licensor header">
            <span><strong>Licensor</strong></span>
            <span className="text-center"><strong>Trademark</strong></span>
            <span className="text-right"><strong>Authorization</strong></span>
          </div>
          {url.licensors.map((l: any) => <div className="enforce-url-licensor">
            <div className="url-licensor-col">
              <span onClick={() => removeLicensor(Number(l.id))} className="remove-icon"><FontAwesomeIcon icon={faTimes} /></span>
              <img src={l.affinityImage.getSize('th')} className="img-responsive" />
              <strong>{l.shortName}</strong>
            </div>
            <div onClick={() => setSelectedTrademark(Number(l.id))} className="url-trademark-col">
              {l.trademarks.length ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                {l.trademarks[0].image ? <img src={l.trademarks[0].image.getSize('th')} className="img-responsive" /> : l.trademarks[0].title }
              </div>
                : <div className="text-center">No trademark <span>Set</span></div>}
            </div>
            <div className="url-auth-col">
              <div onClick={() => toggleSellerAuthorization(Number(l.id), url.seller.wasAuthorized(Number(l.id)))}>
                {url.seller.wasAuthorized(Number(l.id)) ? <span className="text-success">
                  <FontAwesomeIcon icon={faCheckCircle} /> Authorized

                </span> : <span className="text-danger"><FontAwesomeIcon icon={faTimesCircle} /> Not Authorized</span>}
              </div>

            </div>

          </div>)}

        </div>
        <div onClick={() => setAddLicensorModal(true)} className="enforce-url-add-licensor">
          <FontAwesomeIcon icon={faPlus} /> Add Licensor

        </div>
      </div>
    </div>
    <GatewayModal
      type={ModalType.Primary}
      shown={accountLinkModal}
      onClose={() => setAccountLinkModal(false)}
      title={'Link Account'}
    >
      <Modal.Body>
        <p>
          Search all vendor accounts and select an account to link with this seller.
        </p>
        <VendorTypeahead selected={selectedVendor} onChange={setSelectedVendor}   multiple={false}/>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setAccountLinkModal(false)} className="btn btn-default">
          Close
        </button>
        <button onClick={linkAccount} className="btn btn-primary pull-right">{submitting ? 'Linking...' : 'Link Vendor'}</button>
      </Modal.Footer>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={accountCreateModal}
      onClose={() => setAccountCreateModal(false)}
      title={'Create Account'}
    >
      <Modal.Body>
        <p>
          Are you sure you want to create a new account?
        </p>
        <label>Account Status</label>
        <select name="status" className="form-control" value={accountCreateStatus} onChange={e => setAccountCreateStatus(Number(e.target.value))}>
          <option value="1">Potential</option>
          <option value="2">Current</option>
          <option value="3">Cancelled</option>
          <option value="4">Registered</option>
          <option value="5">Illegal</option>
          <option value="6">Out of Business</option>
          <option value="7">Non-Producing</option>
          <option value="8">One Time</option>
          <option value="11">Non-Issue</option>
          <option value="12">Retailer</option>
        </select>

      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setAccountCreateModal(false)} className="btn btn-default">
          Close
        </button>
        <button onClick={createAccount} className="btn btn-primary pull-right">{submitting ? 'Creating..' : 'Create Account'}</button>
      </Modal.Footer>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={addLicensorModal}
      onClose={() => setAddLicensorModal(false)}
      title={'Add Licensor'}
    >
      <form onSubmit={addLicensorForm.handleSubmit(addLicensor)}>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Licensor</label>
              <LicensorTypeahead
                selected={addLicensorForm.watch('licensor')}
                onChange={l => addLicensorForm.setValue('licensor', l)}
                multiple={false}
              />
            </div>
            <p className="text-center">Licensor will be added with their default trademark.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => setAddLicensorModal(false)} className="btn btn-default">
            Close
          </button>
          <button type="submit" className="btn btn-primary pull-right">{submitting ? 'Adding...' : 'Add Licensor'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={selectedTrademark !== null}
      onClose={() => setSelectedTrademark(null)}
      title={'Update Trademark'}
    >
      <form onSubmit={updateTrademarkForm.handleSubmit(updateTrademark)}>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Trademark</label>
              {selectedTrademark ? <TrademarkTypeahead
                accountId={selectedTrademark}
                selected={updateTrademarkForm.watch('trademark')}
                onChange={l => updateTrademarkForm.setValue('trademark', l)}
                multiple={false}
              /> : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => setSelectedTrademark(null)} className="btn btn-default">
            Close
          </button>
          <button type="submit" className="btn btn-primary pull-right">{submitting ? 'Updating...' : 'Update Trademark'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={adminSettingsModal}
      onClose={() => setAdminSettingsModal(false)}
      title={'Admin Settings'}
    >
      <form onSubmit={adminSettingsForm.handleSubmit(saveAdminSettings)}>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label htmlFor="adminUser">Admin User</label>
              <select
                {...adminSettingsForm.register('adminUser')}
                className="form-control"
              >
                <option value="">All Users</option>
                {adminUsers.map(u => <option key={u.id} value={u.id}>{u.fullName}</option>)}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select
                {...adminSettingsForm.register('status')}
                className="form-control"
              >
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>
            </div>
            {adminSettingsForm.watch('status') === 'closed' ? <div className="form-group">
              <label>Closed Date</label>
              <input
                {...adminSettingsForm.register('closedDate', { required: adminSettingsForm.watch('status') === 'closed' })}
                type="date"
                className="form-control"
              />
              {adminSettingsForm.formState.errors.closedDate && <p className="small text-danger">Closed Date is required.</p>}
            </div> : null}
            <div className="form-group">
              <strong>Ignore Seller</strong>
              <div className="checkbox">
                <label htmlFor="ignoreSeller"> <input type="checkbox" {... adminSettingsForm.register('ignoreSeller')} />  Ignore Seller</label>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => setAdminSettingsModal(false)} className="btn btn-default">
            Close
          </button>
          <button type="submit" className="btn btn-primary pull-right">{submitting ? 'Saving...' : 'Save'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>

  </div>);

  return (
    <FullContent >
      <ContentWithSidebar columnSizes={{ main: 'col-xl-9 col-lg-8 col-md-7', sidebar: 'col-xl-3 col-lg-4 col-md-5' }} main={body} sidebar={sidebar} />
    </FullContent>
  );
};
