import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { AccountWebUrlType, AffinityImage } from '../../../shared';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { FullContent } from '../../ContentFrame';
import { WebBotListingItem } from './WebBotListingItem';

interface DashboardPlatform {
  platform: AccountWebUrlType;
  sellers: number;
  listings: number;
  removed: number;
  pending: number;
  review: number;
  recent: WebBotUrl[];
}

interface DashboardCounts {
  sellers: number;
  listings: number;
  removed: number;
  pending: number;
  review: number;
}

export const EnforcementDashboardPage = () => {
  const match = useRouteMatch();
  const [platforms, setPlatforms] = React.useState<DashboardPlatform[]>([]);
  const [counts, setCounts] = React.useState<DashboardCounts>({
    sellers: 0,
    listings: 0,
    removed: 0,
    pending: 0,
    review: 0,
  });
  const [recentlyRemoved, setRecentlyRemoved] = React.useState<WebBotUrl[]>([]);

  React.useEffect(() => {
    getUrls();
    getSummary();

  },              []);

  if (!match) {
    return <Redirect to="/" />;
  }

  const getUrls =  async () => {
    const response = await fetch(`/api/enforcement/urls?licensor=${match.params['licensorId']}&status=2&limit=3`);

    const data = await response.json();

    setRecentlyRemoved(data.data.map((r: any) => WebBotUrl.fromApi(r)));
    console.log(data);
  };

  const getSummary =  async () => {
    const response = await fetch(`/api/enforcement/dashboard?licensor=${match.params['licensorId']}`);

    const data = await response.json();

    const p = data.data.platforms.map((p: any) => {
      const d = p;
      d.platform = AccountWebUrlType.fromApi(d.platform);
      d.recent = d.recent.map((w: any) => WebBotUrl.fromApi(w));
      return d;
    });

    setPlatforms(p);
    setCounts(data.data.counts);
    console.log(data);
  };

  return (
    <FullContent>
      <div>
        <h3>Enforce</h3>
        <p className="text-muted small">Monitor and manage content found on ecommerce platforms.</p>
        <div className="enforcement-dashboard-detail-panels" >
          <div className="panel panel-portal">
            <div className="panel-body">
              <h6 className="text-primary">Sellers</h6>
              <h3>{counts.sellers}</h3>
            </div>
          </div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h6 className="text-primary">Listings</h6>
              <h3><Link style={{ color: '#333' }} to={'enforce/urls'} >{counts.listings}</Link></h3>
            </div>
          </div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h6 className="text-primary">All-time removed</h6>
              <h3>{counts.removed}</h3>
            </div>
          </div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h6 className="text-primary">Pending removal</h6>
              <h3>{counts.pending}</h3>
            </div>
          </div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h6 className="text-primary">Needs review</h6>
              <h3>{counts.review}</h3>
            </div>
          </div>

        </div>

        <h5 className="text-muted">Ecommerce Platforms</h5>

        <div className="enforcement-dashboard-platforms">
          {platforms.map(p => (
            <div className="panel panel-portal enforcement-dashboard-platform">
              <div className="platform-header">
                <Link to={`enforce/urls?platform=${p.platform.id}`} className="logo-plat">
                  <div style={{ backgroundColor: p.platform.color }} className="platform-logo">
                    <i className={p.platform.icon} />
                  </div>
                  <h4 style={{ color: '#333' }}>{p.platform.name}</h4>

                </Link>
                <div>
                  <FontAwesomeIcon className="text-muted" icon={faCog} />
                </div>

              </div>

              {p.recent.length ? <div className="recent-items">
                  {p.recent.map(r => (
                    <div style={{ backgroundImage: `url(${r.imageUrl})` }} className="recent-item-img-container">
                    </div>
                  ))}
              </div> : <div className="no-recent-products">
                  <span className="text-muted"><strong>No Recent Products</strong></span>
                </div>}

              <div className="detail-rows">
                <div><span>Sellers</span><strong>{p.sellers}</strong></div>
                <div><span>Listings</span><strong>{p.listings}</strong></div>
                <div><span>All-time removed</span><strong>{p.removed}</strong></div>
                <div><span>Pending Removal</span><strong>{p.pending}</strong></div>
                <div className="text-primary"><span>Needs review</span><strong>{p.review}</strong></div>

              </div>
              <div className="last-scan text-muted small">
                Last scan Oct 4, 2024
              </div>

            </div>

          ))}

        </div>

        <h5 className="text-muted">Recently Removed</h5>

        <div className="recently-removed">
          {recentlyRemoved.map(r =>
            <WebBotListingItem listing={r} />,
          )}

        </div>

      </div>
    </FullContent>
  );
};
