import Axios from 'axios';
import * as React from 'react';
import { MenuItem, Token, Typeahead } from 'react-bootstrap-typeahead';
import { Trademark } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: Trademark[];
  onChange: (trademarks: Trademark[]) => void;
  multiple: boolean;
  accountId: number | string;
  delayLoad?: boolean;
  preloadedTrademarks?: Trademark[];
}

type Status = LoadableStatuses<Trademark[], string>;

export const TrademarkTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'success', content: [] });

  React.useEffect(() => {
    if (props.preloadedTrademarks && props.preloadedTrademarks.length) {
      setStatus({ state: 'success', content: props.preloadedTrademarks });
    } else if (props.accountId && !props.delayLoad) {
      getTrademarks();
    }
  },              []);

  React.useEffect(() => {
    if (props.preloadedTrademarks && props.preloadedTrademarks.length) {
      setStatus({ state: 'success', content: props.preloadedTrademarks });
    } else if (props.accountId && !props.delayLoad) {
      getTrademarks();
    }
  },              [props.accountId]);

  async function getTrademarks() {
    setStatus({ state: 'loading' });
    try {
      const t = await Axios.get('/api/trademarks', { params: { account_id: props.accountId } });
      const trademarks = t.data.data.map((u: any) => Trademark.fromApi(u));
      setStatus({ content: trademarks, state: 'success' });
    } catch (e) {
      setStatus({ state: 'success', content: [] });
    }
  }

  function renderToken(selected: Trademark, tokenProps: any, index: number) {
    return (
      <Token
        key={index}
        onRemove={tokenProps.onRemove}
        style={{
          width: '100%',
          padding: 10,
          backgroundColor: '#f2f8fc',
          borderRadius: 4,
        }}
      >
        <div style={{ alignItems: 'center' }} className="flex-container">
          {selected.image ? <img style={{ height: 50, width: 50 }} src={selected.image.getSize('th')} /> : <div style={{ height: 50, width: 50 }} />}
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', marginLeft: 10 }}>
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', marginLeft: 10 }}>
              <strong>{selected.gsGroups.map(g => g.groupTitle).join(', ')}</strong>
              <span className="text-muted">Serial #{selected.serialNumber} - Reg #{selected.registrationNumber}</span>
            </div>
          </div>
        </div>
      </Token>
    );
  }

  function renderItem(trademark: Trademark, tokenProps: any, index: number) {
    return (
      <MenuItem
        key={index}
        position={index}
        option={trademark}
      >
        <div style={{ alignItems: 'center' }} className="flex-container">
          {trademark.image ? <img style={{ height: 50, width: 50 }} src={trademark.image.getSize('th')} /> : <div style={{ width: 50, height: 50 }} />}
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', marginLeft: 10 }}>
            <strong>{trademark.gsGroups.map(g => g.groupTitle).join(', ')}</strong>
            <div>
              <span>Serial #{trademark.serialNumber} - Reg #{trademark.registrationNumber}</span>
            </div>
          </div>
        </div>
      </MenuItem>
    );
  }

  const options = status.state === 'success' ? status.content : [];
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      id={`trademark-typeahead-${props.accountId}`}
      placeholder={props.multiple ? 'Select Trademarks' : 'Select Trademark'}
      options={options}
      selected={props.selected}
      onChange={(v: Trademark[]) => {
        if (props.multiple) {
          props.onChange(v);
        } else {
          const current = props.selected;
          if (current.length) {
            const selectedId = current[0].id;
            props.onChange(v.filter(s => s.id !== selectedId));
          } else {
            props.onChange(v);
          }
        }
      }}
      onFocus={() => {
        if (props.delayLoad && !options.length && props.accountId) {
          getTrademarks();
        }
      }}
      multiple={true}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      minLength={0}
      labelKey={(item: Trademark) => item.title}
      renderToken={renderToken}
      renderMenuItemChildren={renderItem}
      disabled={!props.accountId}
    />
  );
};
