import { faCircle, faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AffinityImage } from '../../../shared';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { WebBotUrlFilters } from '../WebBotUrlFilters';

interface IProps {
  listing: WebBotUrl;
}

export const WebBotListingItem = (props: IProps) => {
  const listing = props.listing;

  const navlink = () => {
    const filters = WebBotUrlFilters.getInitialFilters(location.search);
    return `urls/${listing.id}?${filters.generateQueryParamString()}`;
  };

  return (
    <div className="panel panel-portal">
      <div className="panel-body">
        <Link to={navlink()} className="web-bot-listing-item">
          <div className={`wb-status ${listing.statusColor}`}>
            <FontAwesomeIcon icon={faCircle} /> <strong>{listing.status.name}</strong>

          </div>
          <div className="wb-image-brand">
            <img className="img-responsive" src={listing.imageUrl} />
            <div style={{ backgroundColor: listing.type.color }} className="platform-logo">
              <i className={listing.type.icon} />
            </div>
          </div>
          <div className="wb-seller">
            <img src={listing.seller.image ? listing.seller.image.getSize('sm') : ''} className="wb-seller-logo" />
            <div className="wb-seller-info">
              <div className="wb-seller-name small"><strong>{listing.seller.platformUsername}</strong></div>
              <div className="wb-seller-status small">
              </div>
            </div>
          </div>
          <p className="wb-item-desc">
            {listing.name}
          </p>
          <p className="small text-muted wb-first-scan">
            First scan Oct 4, 2024
          </p>
        </Link>

      </div>

    </div>

  );
};
