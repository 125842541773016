import * as React from 'react';
import { AccountWebUrlType } from '../../shared';

interface IProps {
  type: AccountWebUrlType;
  size?: number;
}

export const ExternalPlatformLogo = (props: IProps) => {
  const type = props.type;
  const size = props.size;
  return  (
    <div style={{ backgroundColor: type.color, width: size, height: size }} className="platform-logo">
      <i className={type.icon} />
    </div>
  );
};
