import { CRS, Icon, ImageOverlay, LatLngBounds } from 'leaflet';
import * as React from 'react';
import { Map, MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet';

interface IProps {
  image: string;

}

export const AffinityLeafletImage = (props: IProps) => {

  const [loading, setLoading] = React.useState(true);
  const [image, setImage] = React.useState<HTMLImageElement>();
  const mapRef = React.useRef<any>();

  const loadImageLayer = () => {
    const tempImg = document.createElement('img');
    tempImg.src = props.image;
    tempImg.onload = (() => {
      setLoading(false);
      setImage(tempImg);
    });
  };

  const overlay = image ? (
    <AffinityLeafletImageOverlay url={props.image} image={image} />
  ) : null;

  const iterationBody = (
      <div >
        <Map
          whenReady={loadImageLayer}
          style={{ height: 500 }}
          center={[270.69, 129.29]}
          zoom={2}
          minZoom={-1}
          maxZoom={3}
          zoomSnap={0}
          attributionControl={false}
          crs={CRS.Simple}
          keyboard={true}
          zoomAnimation={false}
          zoomDelta={.4}
          ref={ref => mapRef.current = ref}
        >
          {overlay}
        </Map>

      </div>

    );
  return (
      <div className="row">
        <div className="col-md-12" >
        {iterationBody}
        </div>
      </div>
  );
};

interface AffinityLeafletImageProps extends MapLayerProps {
  image: HTMLImageElement;
  url: string;
}

class ALImageOverlay extends MapLayer<AffinityLeafletImageProps, ImageOverlay>{
  createLeafletElement(props: AffinityLeafletImageProps): ImageOverlay {
    if (props.leaflet && props.leaflet.map) {
      const map = props.leaflet.map;
      const southWest = map.unproject([0, props.image.naturalHeight], map.getMaxZoom() - 1);
      const northEast = map.unproject([props.image.naturalWidth, 0], map.getMaxZoom() - 1);
      const bounds = new LatLngBounds(southWest, northEast);
      const overlay = new ImageOverlay(props.url, bounds, { interactive: true });
      // recenter image
      map.fitBounds(bounds, {
        padding: [5, 5],
        animate: false,
      });
      return overlay;
    }
    const fakeBounds = new LatLngBounds({ lat: 123, lng: 123 }, { lat: 123, lng: 123 });
    const el = new ImageOverlay('', fakeBounds);
    return el;
  }

}

const AffinityLeafletImageOverlay =  withLeaflet<AffinityLeafletImageProps>(ALImageOverlay);
