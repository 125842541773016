import * as React from 'react';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router';
import { AccountWebUrlType, Filters, getInitialFilters } from '../../../shared';
import { WebBotUrl, WebBotUrlApiResponse } from '../../../shared/WebBotUrl';
import { FullContent } from '../../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter } from '../../shared';
import { WebBotListingItem } from '../Dashboard';
import { WebBotUrlFilters } from '../WebBotUrlFilters';

export const WebBotUrlIndexPage = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const [urls, setUrls] = React.useState<WebBotUrl[]>([]);
  const [urlTypes, setUrlTypes] = React.useState<AccountWebUrlType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState(WebBotUrlFilters.getInitialFilters(location.search));

  if (!match) {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getUrlTypes();
  },              []);

  React.useEffect(() => {
    getUrls();
  },              [location.search]);

  const getUrls =  async () => {
    setLoading(true);
    const params = filters.generateApiParamString(match.params['licensorId']);
    const response = await fetch(`/api/enforcement/urls?${params}`);
    const data = await response.json();

    setUrls(data.data.map((r: WebBotUrlApiResponse) => WebBotUrl.fromApi(r)));
    setLoading(false);
  };

  const getUrlTypes = async () => {
    const r = await fetch('/api/web-url-types');
    const data = await r.json();
    setUrlTypes(data.data.map((t: any) => AccountWebUrlType.fromApi(t)));
  };

  const filter = (value: any, submit: boolean) => {
    console.log(value, submit);
    const f = new WebBotUrlFilters();
    f.setFilters(filters.allFilters);
    f.setFilters(value);
    setFilters(f);
    if (submit) {
      const qs = f.generateQueryParamString();
      history.push(`${location.pathname}?${qs}`);
    }
  };

  return (
    <FullContent>
      <h3><strong>Listing Review</strong></h3>
      <p style={{ marginBottom: 20 }} className="text-muted">Monitor and manage content found on ecommerce platforms.</p>
      <FilterBar
        useSearch={true}
        search={filters.search}
        updateFilters={filter}
        usePerPage={false}
        useQuarter={false}
      >
        <div className="col-lg-3 col-sm-3 col-xs-12">
          <select
            onChange={v => filter({ platform: v.target.value }, true)}
            value={filters.platform}
            className="form-control input-sm"
          >
            <option value={0}>All Platforms</option>
            {urlTypes.map(t => <option value={t.id}>{t.name}</option>)}
          </select>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-12">
          <select
            onChange={v => filter({ status: v.target.value }, true)}
            value={filters.status}
            className="form-control input-sm"
          >
            <option value={0}>All Listings</option>
            <option value={1}>Needs Review</option>
            <option value={2}>Removed</option>
            <option value={5}>Authorized</option>
            <option value={6}>Processing</option>
            <option value={7}>Needs Takedown</option>
            <option value={8}>Pending Removaal</option>
          </select>
        </div>

        <div className="col-lg-3 col-sm-3 col-xs-12">
          <select
            onChange={v => filter({ sortBy: v.target.value }, true)}
            value={filters.sortBy}
            className="form-control input-sm"
          >
            <option value={''}>Sort Created, Descending</option>
            <option value={'created-asc'}>Sort Created, Ascending</option>
          </select>
        </div>
      </FilterBar>
      {loading ? <LoadingSpinner /> : <div>
        <div className="web-bot-url-index-grid">
          {urls.map(u => (<WebBotListingItem listing={u} />))}
        </div>
        <PaginationFooter hasNext={true} currentPage={filters.page} setPage={page => filter({ page }, true)} />
      </div>}
    </FullContent>
  );
};
